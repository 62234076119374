import { lazy } from "react";
import Loadable from "../ui-component/Loadable";

const AuthGuard = Loadable(
  lazy(() => import("../views/pages/authentication/AuthGuard"))
);
const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const MainOrders = Loadable(lazy(() => import("../views/order/MainOrders")));
const OrderDetails = Loadable(
  lazy(() => import("../views/order/OrderDetails"))
);

//store
const AddStore = Loadable(lazy(() => import("../views/store/AddStore")));
const ListStore = Loadable(lazy(() => import("../views/store/ListStore")));
const ChangePassword = Loadable(lazy(() => import("../views/pages/authentication/ChangePassword")));
const StoreSettings = Loadable(
  lazy(() => import("../views/store/StoreSettings"))
);
const StoreProfile = Loadable(
  lazy(() => import("../views/store/StoreProfile"))
);
const Addon = Loadable(
  lazy(() => import("../views/store/addons/Addon"))
);
const Brand = Loadable(
  lazy(() => import("../views/store/brand/brand"))
);
const MenuOptions = Loadable(
  lazy(() => import("../views/store/options/MenuOptions"))
);
const Category = Loadable(
  lazy(() => import("../views/store/category/Category"))
);
const Menu = Loadable(
  lazy(() => import("../views/store/menu/Menu"))
);
const MenuUpload = Loadable(
  lazy(() => import("../views/store/adminAction/MenuUpload"))
);

//order
const ReviewOrder = Loadable(
  lazy(() => import("../views/order/review/ReviewOrder"))
);
const DeliveryBoyAdd = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyAdd"))
);
const CustomerService = Loadable(
  lazy(() => import("../views/customerService/CustomerService"))
);

//offer
const ComboOffer = Loadable(
  lazy(() => import("../views/offer/ComboOffer"))
);
const DiscountOffer = Loadable(
  lazy(() => import("../views/offer/DiscountOffer"))
);
const StoreOffer = Loadable(
  lazy(() => import("../views/offer/StoreOffer"))
);
const CategoryOffer = Loadable(
  lazy(() => import("../views/offer/CategoryOffer"))
);
const ItemOffer = Loadable(
  lazy(() => import("../views/offer/ItemOffer"))
);
const NewCustomerCode = Loadable(
  lazy(() => import("../views/offer/NewCustomerCode"))
);
const StoreFirstOrder = Loadable(
  lazy(() => import("../views/offer/StoreFirstOrder/StoreFirstOrder"))
);
//performance Dialy
const DialyPerformance = Loadable(
  lazy(() => import("../views/report/performance/DialyPerformance"))
);
const WeeklyPerformance = Loadable(
  lazy(() => import("../views/report/performance/WeeklyPerformance"))
);
const MonthlyPerformance = Loadable(
  lazy(() => import("../views/report/performance/MonthlyPerformance"))
);

//Summary
const DialySummary = Loadable(
  lazy(() => import("../views/report/summary/DialySummary"))
);
const WeeklySummary = Loadable(
  lazy(() => import("../views/report/summary/WeeklySummary"))
);
const MonthlySummary = Loadable(
  lazy(() => import("../views/report/summary/MonthlySummary"))
);
//invoice
const MonthlyInvoice = Loadable(
  lazy(() => import("../views/report/MonthlyInvoice"))
);

//notification
const Notification = Loadable(
  lazy(() => import("../views/notification/Notification"))
);
const UserUpdate = Loadable(
  lazy(() => import("../views/pages/authentication/updateuser"))
);

//complaits
const Complaints = Loadable(
  lazy(() => import("../views/feedback/Complaints"))
);

const CategoryTypes = Loadable(
  lazy(() => import("../views/settings/categoryTypes/CategoryTypes"))
);
const StoreCusins = Loadable(
  lazy(() => import("../views/settings/cusins/StoreCusins"))
);

const ViewMenu = Loadable(
  lazy(() => import("../views/store/menu/ViewMenu"))
);

//audit trailas
const AuditTrails = Loadable(
  lazy(() => import("../views/audit/AuditTrails"))
);

//acount settings
const BranchPartner = Loadable(
  lazy(() => import("../views/accounts/branchPartner/BranchPartner"))
);

const CustomerCareAdmin = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareAdmin"))
);
const CustomerCareExecutive = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareExecutive"))
);

const AccountantAdmin = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountantAdmin"))
);
const AccountsManager = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsManager"))
);
const AccountsExecutive = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsExecutive"))
);
const MarketingAdmin = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingAdmin"))
);
const MarketingExecutive = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingExecutive"))
);
const OfficeStaff = Loadable(
  lazy(() => import("../views/accounts/officeStaff/OfficeStaff"))
);

const Area = Loadable(
  lazy(() => import("../views/settings/area/Area"))
);
const Role = Loadable(
  lazy(() => import("../views/settings/role/Role"))
);
const Permission = Loadable(
  lazy(() => import("../views/settings/role/rolePermission"))
);
// const StoreFirstOrder = Loadable(
//   lazy(() => import("../views/offer/StoreFirstOrder/StoreFirstOrder"))
// );
const UserNotification = Loadable(
  lazy(() => import("../views/userNotification/userNotification"))
);
const DeliveryBoyList = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyList"))
);

const CustomerOrder = Loadable(
  lazy(() => import("../views/customerService/orderDetailsCustomer"))
);
const GuestNotification = Loadable(
  lazy(() => import("../views/notification/guestNotification"))
);
const StoreOffcer = Loadable(
  lazy(() => import("../views/accounts/storeOfficer/OfficeStaff"))
);
const TabaqCoupon = Loadable(
  lazy(() => import("../views/offer/couponCode/TabaqCoupon"))
);
const StoreOrder = Loadable(
  lazy(() => import("../views/store/storeOrder"))
);
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      title: "Dashboard",
      path: '/dashboard',
      element: <DashboardDefault />,
    },
    {
      title: "Store",
      path: "/store",
      children: [
        {
          title: "Store",
          path: "add",
          element: <AddStore />,
        },

        {
          title: "Store",
          path: "list",
          element: <ListStore />,
        },
        {
          title: "Store",
          path: "settings",
          element: <StoreSettings />,
        },
        {
          title: "Store",
          path: "storeProfile/:id",
          element: <StoreProfile />,
        },
        {
          title: "Store",
          path: "storeProfile/addons/:id",
          element: <Addon />,
        },
        {
          title: "Store",
          path: "storeProfile/brands/:id",
          element: <Brand />,
        },
        {
          title: "Store",
          path: "storeProfile/options/:id",
          element: <MenuOptions />,
        },
        {
          title: "Store",
          path: "storeProfile/category/:id",
          element: <Category />,
        },
        {
          title: "Store",
          path: "storeProfile/menu/:id",
          element: <Menu />,
        },
        {
          title: "Store",
          path: "storeProfile/viewMenu/:storeId/:catID",
          element: <ViewMenu />,
        },
        {
          title: "Menu/Item",
          path: "menuUpload",
          element: <MenuUpload />,
        },
        {
          title: "Store",
          path: "storeorder/:id",
          element: <StoreOrder />,
        },
      ],
    },
    {
      title: 'Menu/Item',
      path: "/store",
      children: [
        {
          title: 'Menu/Item',
          path: "menuUpload",
          element: <MenuUpload />,
        },
      ],
    },
    {
      title: 'Delivery Boy',
      path: "/deliveryBoy",
      children: [
        {
          title: 'Delivery Boy',
          path: "add",
          element: <DeliveryBoyAdd />,
        },
        {
          title: 'Delivery Boy',
          path: "list",
          element: <DeliveryBoyList />,
        },
      ],
    },
    {
      title: "Settings",
      path: "/settings",
      children: [
        {
          title: "Category Types",
          path: "category-types",
          element: <CategoryTypes />,
        },
        {
          title: "Store Cusins",
          path: "cusins",
          element: <StoreCusins />,
        },
        {
          title: "Area",
          path: "area",
          element: <Area />,
        },
        {
          title: "Role",
          path: "role",
          element: <Role />,
        },
        {
          title: "Permission",
          path: "permission",
          element: <Permission />,
        },
      ],
    },
    {
      path: "/offer",
      title: "Offer",
      children: [
        {
          title: "Offer",
          path: "combo_offer",
          element: <ComboOffer />,
        },
        {
          title: "Offer",
          path: "discount",
          element: <DiscountOffer />,
        },
        {
          title: "Offer",
          path: "store",
          element: <StoreOffer />,
        },
        {
          title: "Offer",
          path: "category",
          element: <CategoryOffer />,
        },
        {
          title: "Offer",
          path: "item",
          element: <ItemOffer />,
        },
        {
          title: "Offer",
          path: "newCustomer",
          element: <NewCustomerCode />,
        },
        {
          title: "Offer",
          path: "StoreNewOrderOffer",
          element: <StoreFirstOrder />,
        },
        {
          title: "Offer",
          path: "coupon-code",
          element: <TabaqCoupon />,
        },
      ],
    },
    {
      path: "/customerService",
      title: 'Customer Service',
      // element: <CustomerService />,
      children: [
        {
          title: 'Customer Service',
          path: "list",
          element: <CustomerService />,
        },
        {
          title: 'Customer Service',
          path: "customerorder/:id",
          element: <CustomerOrder />,
        },
      ],
    },
    // {
    //   title: 'Customer Service',
    //   path: "/branch",
    //   children: [
    //     {
    //       path: "order",
    //       element: <MainOrders />,
    //     },
    //   ],
    // },
    {
      path: "/notification/:type",
      title: 'Notification',
      element: <Notification />,

    },
    {
      path: "/notification/guest",
      title: 'Notification',
      element: <GuestNotification />,
    },
    {
      title: 'Settings',
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      title: "Account Management",
      path: "/accounts",
      children: [
        {
          title: "Account Management",
          path: "branch-partner",
          element: <BranchPartner />,
        },
        {
          title: "Account Management",
          path: "custumercare-admin",
          element: <CustomerCareAdmin />,
        },
        {
          title: "Account Management",
          path: "custumercare-executive",
          element: <CustomerCareExecutive />,
        },
        {
          title: "Account Management",
          path: "accountant-admin",
          element: <AccountantAdmin />,
        },
        {
          title: "Account Management",
          path: "accounts-manager",
          element: <AccountsManager />,
        },
        {
          title: "Account Management",
          path: "accounts-executive",
          element: <AccountsExecutive />,
        },
        {
          title: "Account Management",
          path: "marketing-admin",
          element: <MarketingAdmin />,
        },
        {
          title: "Account Management",
          path: "marketing-executive",
          element: <MarketingExecutive />,
        },
        {
          title: "Account Management",
          path: "office-staff",
          element: <OfficeStaff />,
        },
        {
          title: "Account Management",
          path: "store-officer",
          element: <StoreOffcer />,
        },

      ],
    },
    {
      title: "Settings",
      path: "/userUpdate",
      element: <UserUpdate />,
    },
    {
      title: "Profile",
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      title: "User Notification",
      path: "/notification/view",
      element: <UserNotification />,
    },
    {
      title: "Report",
      path: "/report/invoice",
      element: <MonthlyInvoice />,
    },
    {
      title: 'Complaints And Feedback',
      path: "/feedback",
      element: <Complaints />,
    },
    {
      path: "/order",
      title: "Order",
      children: [
        // {
        //   path: "view",
        //   title :"Order",
        //   element: <MainOrders />,
        // },
        // {
        //   path: "review",
        //   title :"Order",
        //   element: <ReviewOrder />,
        // },
        {
          path: "orderDetails/:id",
          title: "Order",
          element: <OrderDetails />,
        },
        // {
        //   path: "onlineOrder",
        //   title :"Order",
        //   element: <OrderDetails />,
        // },


      ],
    },
  ],
};

export default MainRoutes;
