// Reducer function
import {createSlice} from '@reduxjs/toolkit';
export const PERMISSION = 'PERMISSION'

  
export const permission = createSlice({
    name :'Permission',
    initialState : {
        storedData: null,
    },
    reducers : {
        storeData:(state,action)=>{
            state.storedData = action.payload;
        }
    }
})
export const {storeData} = permission.actions;
export default permission.reducer;